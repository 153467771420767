import { navigate } from 'gatsby';
import React, { FC, ReactNode, useRef, useState } from 'react';
import styled from 'styled-components';
import { marginTop } from '../constants/ContentStyle';
import useModal from '../hooks/useModal';
import getRandomIndex from '../utils/getRandomIndex';
import shuffleMedia from '../utils/shuffleMedia';
import slugify from '../utils/slugify';
import Body from './Body';
import Img from './Img';
import Layout from './Layout';
import Modal from './Modal';
import VideoPlayer from './VideoPlayer';
import Window from './Window';
import Markdown from 'markdown-to-jsx';

type MediaPosition = 'left' | 'right' | 'inWindow';

export type Image = {
    photo_url: string;
    photo_alt: string;
    photo_title: string;
};

export type Video = {
    video_url: string;
    video_title: string;
    video_slug: string;
};

export type Media = Video | Image;

interface Props {
    windowTitle: string;
    description: string;
    images?: Image[];
    isShowWindow?: boolean;
    videos?: Video[];
    showBackgroundGradient?: boolean;
    showBackgroundTriangle?: boolean;
    extraInfo?: ReactNode;
    highlightedVideo?: Video;
    highlightedPhoto?: Image;
    metaData?: {
        description?: string;
        title?: string;
        keywords: [
            {
                keyword: string;
            }
        ];
    };
}

const PageWindow: FC<Props> = ({
    windowTitle,
    description,
    images,
    isShowWindow = false,
    videos,
    showBackgroundGradient,
    showBackgroundTriangle,
    extraInfo,
    metaData,
    highlightedPhoto,
}) => {
    const { isOpen, toggleModal } = useModal();
    const [currentImage, setCurrentImage] = useState<Image | null>(null);
    const allMedia = useRef<Media[]>(
        shuffleMedia([...(videos ?? []), ...(images ?? [])])
    ).current;
    const randomNum = useRef<number>(Math.round(Math.random()));

    const hasMedia = !!allMedia.length;
    const mediaToSlice =
        randomNum.current === 0
            ? Math.floor(allMedia.length / 2)
            : Math.ceil(allMedia.length / 2);

    const handleClick = (img: Image) => {
        if (!isOpen) {
            setCurrentImage(img);
            toggleModal();
        }
    };

    const navigateToVideoPage = (videoSlug: string) =>
        navigate(
            window?.location.pathname +
                (!window?.location.pathname.endsWith('/') ? '/' : '') +
                slugify(videoSlug)
        );

    const renderVideo = (video: Video) => (
        <div key={video.video_url} style={{ marginBottom: '25px' }}>
            <VideoPlayer
                url={video.video_url}
                onClick={() => navigateToVideoPage(video.video_slug)}
            />
        </div>
    );

    const renderImage = (image: Image, inModal?: boolean) => (
        <button key={image.photo_alt} onClick={() => handleClick(image)}>
            <Image
                className="lazyload"
                imageSrc={image.photo_url}
                alt={image.photo_alt}
                isOpen={isOpen}
                inModal={inModal}
            />
        </button>
    );

    const renderMedia = (media: Media[]) => {
        return media.map((mediaItem) => {
            if ('video_url' in mediaItem) {
                return renderVideo(mediaItem);
            } else {
                return renderImage(mediaItem);
            }
        });
    };

    // TODO:    Move description and keywords in CMS to show,
    //          so that every show can have a unique description and keywords
    const listOfKeywords =
        metaData?.keywords && metaData.keywords.length
            ? metaData.keywords.map((el) => el.keyword)
            : undefined;

    return (
        <Layout
            showFooter
            showBackgroundTriangle={showBackgroundTriangle}
            showBackgroundGradient={showBackgroundGradient}
            title={windowTitle.charAt(0).toUpperCase() + windowTitle.slice(1)}
            description={
                metaData?.description && metaData.description !== ''
                    ? metaData.description
                    : undefined
            }
            metaImage={
                highlightedPhoto?.photo_url ?? (images && images[0]?.photo_url)
            }
            keywords={listOfKeywords}
        >
            <Container>
                {hasMedia && (
                    <Media position="left">
                        {renderMedia(allMedia.slice(0, mediaToSlice))}
                    </Media>
                )}
                <Window windowTitle={windowTitle}>
                    <Content>
                        {extraInfo && (
                            <Info>
                                {extraInfo}
                                {isShowWindow && hasMedia && (
                                    <Media position="inWindow">
                                        {renderMedia(allMedia)}
                                    </Media>
                                )}
                            </Info>
                        )}
                        <Description>
                            <Body>
                                <Markdown>{description}</Markdown>
                            </Body>
                        </Description>
                    </Content>
                </Window>
                {hasMedia && (
                    <Media position="right">
                        {renderMedia(allMedia.slice(mediaToSlice))}
                    </Media>
                )}
            </Container>

            {currentImage && (
                <Modal
                    modalTitle={currentImage.photo_title}
                    isOpen={isOpen}
                    onClose={toggleModal}
                    maxHeightScreen
                >
                    {renderImage(currentImage, true)}
                </Modal>
            )}
        </Layout>
    );
};

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    ${marginTop}
`;

const Media = styled.div<{ position: MediaPosition }>`
    flex-direction: column;
    margin-top: 59px;
    margin-left: ${({ position }) =>
        position === 'left' || position === 'inWindow' ? '0' : '37px'};
    margin-bottom: 100px;
    display: ${({ position }) => (position === 'inWindow' ? 'flex' : 'none')};
    position: relative;

    @media (min-width: ${({ theme }) => theme.mediaQueries.medium}) {
        width: ${({ position }) => (position === 'left' ? '205px' : '236px')};
    }

    @media (min-width: ${({ theme }) => theme.mediaQueries.xl}) {
        display: ${({ position }) =>
            position === 'inWindow' ? 'none' : 'flex'};
    }
`;

const Image = styled(Img)<{
    position?: MediaPosition;
    isOpen: boolean;
}>`
    margin-bottom: 25px;
    height: 206px;
    cursor: ${({ isOpen }) => (isOpen ? 'auto' : 'pointer')};

    width: 100%;
    height: auto;
    max-height: calc(90vh - 50px);

    z-index: ${() => getRandomIndex()};
    position: relative;
`;

const Content = styled.div`
    padding: 16px 18px;
    display: flex;
    flex-direction: column;

    @media (min-width: ${({ theme }) => theme.mediaQueries.medium}) {
        padding: 54px 18px;
        flex-direction: row;
    }
`;

const Info = styled.div`
    flex: 0 0 40%;
    color: ${({ theme }) => theme.colors.black2};

    display: flex;
    flex-direction: column;

    @media (min-width: ${({ theme }) => theme.mediaQueries.medium}) {
        flex: 0 0 30%;
        max-width: 30%;
        margin-right: 25px;
    }
`;

const Description = styled.div`
    flex: 1;
    font-style: italic;
`;

export default PageWindow;
