import { graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import React, { FC } from 'react';
import PageWindow from '../components/PageWindow';

export type Props = {
    data: {
        aboutMe: {
            frontmatter: {
                genericAM: {
                    title: string;
                    photos: [
                        {
                            photo_url: string;
                            photo_alt: string;
                            photo_title: string;
                        }
                    ];
                    videos: [
                        {
                            video_url: string;
                            video_title: string;
                            video_slug: string;
                        }
                    ];
                    showBackgroundTriangle: boolean;
                    showBackgroundGradient: boolean;
                };
                nlAM: {
                    description: string;
                    extra_info: string;
                    meta_description: string;
                };
                enAM: {
                    description: string;
                    extra_info: string;
                    meta_description: string;
                };
            };
        };
        metadata: {
            edges: [
                {
                    node: {
                        childMarkdownRemark: {
                            frontmatter: {
                                [key: string]: {
                                    aboutMeItem: {
                                        description?: string;
                                        keywords: [
                                            {
                                                keyword: string;
                                            }
                                        ];
                                    };
                                };
                            };
                        };
                    };
                }
            ];
        };
    };
};

const About: FC<Props> = ({ data }) => {
    const { locale } = useIntl();

    const {
        genericAM: {
            title,
            photos,
            showBackgroundGradient,
            showBackgroundTriangle,
            videos,
        },
        nlAM,
        enAM,
    } = data.aboutMe.frontmatter;

    const { description, extra_info, meta_description } =
        locale === 'nl' ? nlAM : enAM;
    const metaData =
        data.metadata.edges[0].node.childMarkdownRemark.frontmatter[
            `${locale}Meta`
        ]?.aboutMeItem;

    if (meta_description) {
        metaData.description = meta_description;
    }

    return (
        <PageWindow
            windowTitle={title}
            images={photos}
            videos={videos}
            showBackgroundGradient={showBackgroundGradient}
            showBackgroundTriangle={showBackgroundTriangle}
            description={description}
            extraInfo={extra_info}
            metaData={metaData}
        />
    );
};

export const query = graphql`
    query aboutMe($slug: String!) {
        aboutMe: markdownRemark(fields: { slug: { eq: $slug } }) {
            frontmatter {
                genericAM {
                    title
                    photos {
                        photo_url
                        photo_alt
                        photo_title
                    }
                    videos {
                        video_url
                        video_title
                        video_slug
                    }
                    showBackgroundTriangle
                    showBackgroundGradient
                }
                nlAM {
                    description
                    extra_info
                    meta_description
                }
                enAM {
                    description
                    extra_info
                    meta_description
                }
            }
        }
        metadata: allFile(filter: { absolutePath: { regex: "/meta_data/i" } }) {
            edges {
                node {
                    childMarkdownRemark {
                        frontmatter {
                            nlMeta {
                                aboutMeItem {
                                    description
                                    keywords {
                                        keyword
                                    }
                                }
                            }
                            enMeta {
                                aboutMeItem {
                                    description
                                    keywords {
                                        keyword
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default About;
